import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-autofill-dt';

var dataTable;
var dataTableTwo;
var lastWindowWidth = $(window).width();
var initializationWindowWidth = false;
$(document).ready(function() {
    dataTable = $('#dashboardTable').DataTable({
        searching: true,
        autofill: true,
        scrollY: '125px',
        scrollCollapse: true,
        scrollX: true,
        order: [],
        lengthMenu: [
        [10, 20, 50, -1],
        [10, 20, 50, 'TUTTI'],
        ],
        language: {
            search: "Cerca",
            lengthMenu: 'Visualizza _MENU_ Rischi',
            zeroRecords: 'La ricerca non ha portato alcun risultato.',
            info: 'Pagina _PAGE_ di _PAGES_',
            infoEmpty: 'Nessun Rischio disponibile',
            infoFiltered: '(filtrata da _MAX_ Rischi totali)',
            loadingRecords: "Caricamento..",
            paginate: {
                first:    "Primo",
                last:    "Ultimo",
                next:    "Successivo",
                previous: "Precedente"
            },
        },  
    });

    dataTableTwo = $('#dashboardTableTwo').DataTable({
        searching: true,
        autofill: true,
        scrollY: '125px',
        scrollCollapse: true,
        scrollX: true,
        order: [],
        lengthMenu: [
        [10, 20, 50, -1],
        [10, 20, 50, 'TUTTI'],
        ],
        language: {
            search: "Cerca",
            lengthMenu: 'Visualizza _MENU_ Rischi',
            zeroRecords: 'La ricerca non ha portato alcun risultato.',
            info: 'Pagina _PAGE_ di _PAGES_',
            infoEmpty: 'Nessun Rischio disponibile',
            infoFiltered: '(filtrata da _MAX_ Rischi totali)',
            loadingRecords: "Caricamento..",
            paginate: {
                first:    "Primo",
                last:    "Ultimo",
                next:    "Successivo",
                previous: "Precedente"
            },
        },  
        drawCallback: function() {
            $('#visibilityTable').removeClass('invisible');      
        }
    });


    setInterval(function() {
        var currentWindowWidth = $(window).width();
        if (currentWindowWidth !== lastWindowWidth) {
            lastWindowWidth = currentWindowWidth;
            if (initializationWindowWidth == true) {
                dataTable.draw();
            }else{
                initializationWindowWidth = true;
            }
        }
    }, 500);
});


$('#dashboardTable').on('mouseenter', 'td', function() {
    var td = $(this);
    var id = td.attr('id');
    var parts = id.split('_');
    
    if (isEllipsisActive(td[0])) {
        handleHover(id, 'full_width', parts[0]); 
    }
}).on('mouseleave', 'td', function() {
    var td = $(this);
    var id = td.attr('id');
    var parts = id.split('_');
    handleHover(id, parts[0], 'full_width'); 
});
$('#dashboardTableTwo').on('mouseenter', 'td', function() {
    var td = $(this);
    var id = td.attr('id');
    var parts = id.split('_');
    if (isEllipsisActive(td[0])) {
        handleHover(id, 'full_width', parts[0]); 
    }
}).on('mouseleave', 'td', function() {
    var td = $(this);
    var id = td.attr('id');
    var parts = id.split('_');
    handleHover(id, parts[0], 'full_width'); 
});

function handleHover(id, addClass, removeClass) {
    $('#' + id).addClass(addClass).removeClass(removeClass);
}

function isEllipsisActive(element) {
    var style = window.getComputedStyle(element, null);
    return style.getPropertyValue('overflow') === 'hidden' &&
           style.getPropertyValue('text-overflow') === 'ellipsis' &&
           element.scrollWidth > element.clientWidth;
}